import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { GO_BACK, MESSAGE_RECEIVED, THANK_YOU } from '../assets/texts/texts'

const MessageReceived = ({ language }) => {
  return (
    <Container>
      <Row className='pt-7'>
        <Col />
        <Col md="9">
          <h1>{THANK_YOU[language]}</h1>
          <h2>{MESSAGE_RECEIVED[language]}</h2>
          <Link to={'/'}><h4><u>{GO_BACK[language]}</u></h4></Link>
        </Col>
        <Col />
      </Row>
    </Container>
  )
}

export default MessageReceived
