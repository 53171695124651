import { Language } from './languages.ts'

export const ABOUT = {
  [Language.English]: 'About Us',
  [Language.Estonian]: 'Meist',
  [Language.Norwegian]: 'Om Oss',
};

export const CONTACT = {
  [Language.English]: 'Contact',
  [Language.Estonian]: 'Kontakt',
  [Language.Norwegian]: 'Kontakt',
};

export const NAME = {
  [Language.English]: 'Full Name',
  [Language.Estonian]: 'Ees- ja Perekonnanimi',
  [Language.Norwegian]: 'For- og Etternavn',
};

export const MESSAGE = {
  [Language.English]: 'Message',
  [Language.Estonian]: 'Sõnum',
  [Language.Norwegian]: 'Melding',
};

export const SENDING = {
  [Language.English]: 'Sending...',
  [Language.Estonian]: 'Saadan...',
  [Language.Norwegian]: 'Sender...',
};

export const SEND = {
  [Language.English]: 'Send',
  [Language.Estonian]: 'Saada',
  [Language.Norwegian]: 'Send',
};

export const WEBSITE_TEXT = {
  [Language.English]: `Starting at 249€ for a one page website. Each functionality and each page can add to the price depending on it's complexity. We do everything from fully static sites to slides and 3D animations. All of our websites are built as single page applications. This means there is no load time between switching from one page to the next, giving your customers the smoothest experience when browsing your website.`,
  [Language.Estonian]: `Alates 249€ ühelehelise kodulehe eest. Iga funktsioon ja iga leht lisandub hinnale sõltuvalt selle keerukusest. Loome kõike alates täielikult staatilistest saitidest kuni slaidide ja 3D-animatsioonideni. Kõik meie veebisaidid, ka mitmelehelised, on loodud "üheleheliste" rakendustena. See tähendab, et ühelt lehelt teisele minnes laadimisaeg puudub, mis annab klientidele Teie veebisaidi sirvimisel sujuvaima kogemuse.`,
  [Language.Norwegian]: 'Fra 249€ for et nettsted med én side. Hver funksjonalitet og hver side kan øke prisen avhengig av kompleksiteten. Vi gjør alt fra helt statiske sider til lysbilder og 3D-animasjoner. Alle våre nettsider er bygget som enkeltsides applikasjoner. Dette betyr at det ikke er noen lastetid mellom å bytte fra en side til den neste, noe som gir kundene dine den beste opplevelsen når de surfer på nettstedet ditt.',
};

export const CUSTOM_SOFTWARE_TEXT = {
  [Language.English]: 'Starting as low as 1990€ for something as simple as a custom orders system. Get the software that you need today and you can always rehire us later to further develop your software to your needs as your company grows. Prices are calculated by how many different functionalities the software has and how complex the data structure would be.',
  [Language.Estonian]: 'Alates 1990€ lihtsama tarkvara eest - näiteks isikupärastatud tellimuste süsteem. Hankige tarkvara, mida vajate täna ning saate meid hiljem alati uuesti palgata, et Teie ettevõtte kasvades oma tarkvara vastavalt vajadusele edasi arendada. Hinnad kujunevad selle järgi, kui palju erinevaid funktsioone tarkvaral on ja kui keeruline on andmestruktuur.',
  [Language.Norwegian]: 'Fra så lite som 1990€ for noe så enkelt som et tilpasset bestillingssystem. Skaff deg programvaren du trenger i dag, og du kan alltid ansette oss på nytt senere for å videreutvikle programvaren til dine behov etter hvert som bedriften din vokser. Prisene beregnes etter hvor mange forskjellige funksjoner programvaren har og hvor kompleks datastrukturen vil være.',
};

export const CUSTOM_SOFTWARE = {
  [Language.English]: 'Custom Software',
  [Language.Estonian]: 'Tarkvara Eritellimusel',
  [Language.Norwegian]: 'Tilpasset Programvare',
};

export const WEBSITE = {
  [Language.English]: 'Website',
  [Language.Estonian]: 'Koduleht',
  [Language.Norwegian]: 'Nettsted',
};

export const OPTIONS_HEADER = {
  [Language.English]: 'Possibilities',
  [Language.Estonian]: 'Võimalused',
  [Language.Norwegian]: 'Muligheter',
};

export const SUCCESS = {
  [Language.English]: 'Success!',
  [Language.Estonian]: 'Korras!',
  [Language.Norwegian]: 'Fint!',
};

export const ALL_FIELDS_REQUIRED = {
  [Language.English]: 'All fields are required',
  [Language.Estonian]: 'Kõik väljad on kohustuslikud',
  [Language.Norwegian]: 'Alle felt er obligatoriske',
};

export const SOMETHING_WRONG = {
  [Language.English]: 'Something went wrong',
  [Language.Estonian]: 'Midagi läks valesti',
  [Language.Norwegian]: 'Noe gikk galt',
};

export const THANK_YOU = {
  [Language.English]: 'Thank you!',
  [Language.Estonian]: 'Aitäh!',
  [Language.Norwegian]: 'Tusen takk!',
};

export const MESSAGE_RECEIVED = {
  [Language.English]: 'Your message has been received.',
  [Language.Estonian]: 'Oleme Teie kirja kätte saanud.',
  [Language.Norwegian]: 'Meldingen din er mottatt.',
};

export const GO_BACK = {
  [Language.English]: 'Go back.',
  [Language.Estonian]: 'Mine tagasi.',
  [Language.Norwegian]: 'Gå tilbake.',
};

export const COMPLETE_CAPTCHA = {
  [Language.English]: 'Please complete the captcha',
  [Language.Estonian]: 'Palun täitke captcha',
  [Language.Norwegian]: 'Vennligst fullfør captchaen',
};

export const OPTIONS_TEXT = {
  [Language.English]: `DRY - Which in the world of programming stands for "don't repeat yourself." What is a task that your company does that could be automated by software? Paying for an employee is like an endless subscription. If even a part of their work could be automated by software, you could pay for said software just once. Depending on what they do, the cost could be as low as their salary for a single month - and then it's taken care of forever.`,
  [Language.Estonian]: `DRY - mis programmeerimismaailmas tähendab "don't repeat yourself" ehk "ära korda ennast". Kas mõnel Teie töötajal on ülesanne, mida saaks tarkvara abil automatiseerida? Töötajale palka maksta on justkui lõputu igakuine tellimus. Kui kasvõi osa tema tööst saaks tarkvara abil automatiseerida, maksate selle tarkvara eest vaid korra. Sõltuvalt sellest, mis ülesandega tegu on, võivad kulud olla sama madalad kui töötaja ühe kuu palk - ja siis on see ülesanne igaveseks lahendatud.`,
  [Language.Norwegian]: `DRY - Som i programmeringsverdenen står for "don't repeat yourself" (ikke gjenta deg selv). Hva er en oppgave bedriften din gjør som kan automatiseres av programvare? Å betale for en ansatt er som et endeløst abonnement. Hvis til og med bare en del av arbeidet deres kunne automatiseres av programvare, kan du betale for nevnte programvare bare én gang. Avhengig av hva de gjør, kan kostnadene være så lave som lønnen deres for en enkelt måned - og så vil det bli gjort for alltid.`,
};

export const OPTIONS_SUBTEXT = {
  [Language.English]: 'Orders - Cash register - Inventory management',
  [Language.Estonian]: 'Tellimused - Kassasüsteem - Laohaldus',
  [Language.Norwegian]: 'Bestillinger - Kassesystem - Lagerstyring',
};

export const TIMETABLES_TEXT = {
  [Language.English]: `The Timetables is our free-for-all web application to showcase the power that software has to save costs for your company and have one less thing to worry about. Employees can log shifts in your company's name, either by inputting the times manually, or just pressing "Start Shift" and "End Shift" buttons on the timer each shift. Filter the shifts by either month or week of any year and download the summary on PDF and/or Excel.`,
  [Language.Estonian]: '"Vahetused" on meie kõigile-tasuta veebirakendus, mis tutvustab, kuidas tarkvara võib teie elu lihtsamaks muuta. Töötajad saavad vahetusi Teie ettevõtte nimele logida, sisestades kellaajad ja muud seaded käsitsi või lihtsalt vajutades taimeril nuppe "Alusta vahetust" ja "Lõpeta vahetus". Filtreerige vahetused mis tahes kuu või nädala järgi ja laadige kokkuvõte alla PDF-i ja/või Exceli formaadis.',
  [Language.Norwegian]: 'Timeregistrering er vår gratis-for-alle nettapplikasjon for å vise frem kraften programvaren har for å spare kostnader for bedriften din og ha en ting mindre å bekymre seg for. Ansatte kan logge skift i firmaets navn, enten ved å legge inn tidene manuelt, eller bare trykke på "Start Skift" og "Avslutt Skift"-knappene på timeren hvert skift. Filtrer skiftene etter enten måned eller uke i et hvilket som helst år, og last ned sammendraget på PDF og/eller Excel.',
};

export const TIMETABLES_URL = {
  [Language.English]: 'thetimetables.com',
  [Language.Estonian]: 'vahetused.ee',
  [Language.Norwegian]: 'thetimetables.com',
};

export const TIMETABLES = {
  [Language.English]: 'The Timetables',
  [Language.Estonian]: 'Vahetused',
  [Language.Norwegian]: 'Timeregistrering',
};

export const TIMETABLES_SUBTEXT = {
  [Language.English]: 'Free for all - Employees log their shifts - You download the summary',
  [Language.Estonian]: 'Kõigile tasuta - Töötajad logivad oma vahetused - Ettevõtja laeb alla kokkuvõtte',
  [Language.Norwegian]: 'Gratis for alle - Ansatte logger skiftene sine - Du laster ned sammendraget',
};

export const INTRODUCTION_HEADER = {
  [Language.English]: "Custom software for your company's needs",
  [Language.Estonian]: 'Loome tarkvara vastavalt ettevõtte vajadustele',
  [Language.Norwegian]: 'Tilpasset programvare for bedriftens behov',
};

export const COPIED_TO_CLIPBOARD = {
  [Language.English]: "Copied to clipboard!",
  [Language.Estonian]: 'Link kopeeritud!',
  [Language.Norwegian]: 'Kopiert til utklippstavlen!',
};

export const INTRODUCTION_SUBTEXT = {
  [Language.English]: "Web applications - Mobile applications - Websites",
  [Language.Estonian]: 'Veebirakendused - Mobiilirakendused - Kodulehed',
  [Language.Norwegian]: 'Webapplikasjoner - Mobilapplikasjoner - Nettsider',
};

export const GET_IN_TOUCH = {
  [Language.English]: "Like what you see and have an idea for an app your company could use to make your life easier? Don't hesitate to contact us either through the form below or simply by sending us an email at",
  [Language.Estonian]: 'Kui Teile meeldib, mis võimalusi Vellaware OÜ pakub ning Teil on idee rakendusest, mis võiks Teie ettevõtte igapäevaelu lihtsamaks muuta, siis palun võtke meiega ühendust. Seda saate teha kas alloleva vormi kaudu või lihtsalt saates meile e-kiri aadressil',
  [Language.Norwegian]: 'Liker du det du ser og har en idé til en app bedriften din kan bruke for å gjøre livet ditt enklere? Ikke nøl med å kontakte oss enten gjennom skjemaet nedenfor eller ganske enkelt ved å sende oss en e-post på',
};
