import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import MyNavbar from './MyNavbar'
import Introduction from './Introduction'
import backgroundImg from '../assets/images/vellaware-background.jpg'
import Options from './Options'
import Timetables from './Timetables'
import { Element } from 'react-scroll'
import Contact from './Contact'

const MainPage = ({ language, changeLanguage, domainEnd }) => {
  return (
    <>
      <Container>
        <Row>
          <Col />
          <Col md="9">
            <MyNavbar language={language} changeLanguage={changeLanguage} domainEnd={domainEnd} />
            <Introduction language={language} />
          </Col>
          <Col />
        </Row>
      </Container>
        <div>
          <img
            src={backgroundImg}
            alt="vellaware-background-img"
            style={{ height: '70vh', minWidth: '100%' }}
          />
        </div>
      <Container>
        <Row>
          <Col />
          <Col md="9">
            <hr />
          </Col>
          <Col />
        </Row>
      </Container>
      <Container>
        <Row>
          <Col />
          <Col md="9" >
            <Row>
              <Container>
                <Options language={language} />
                <hr />
                <Timetables language={language} />
                <hr />
                <Element name='contact'>
                  <Contact language={language} />
                </Element>
                <hr />
              </Container>
            </Row>
          </Col>
          <Col />
        </Row>
      </Container>
    </>
  )
}

export default MainPage
