import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import MyNavbar from './components/MyNavbar'
import Timetables from './components/Timetables'
import Introduction from './components/Introduction'
import Contact from './components/Contact'
import { Element } from 'react-scroll';
import Options from './components/Options'
import { selectCurrentLanguage, setLanguage } from './features/language/languageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Language } from './assets/texts/languages.ts'
import backgroundImg from './assets/images/vellaware-background.jpg'
import MessageReceived from './components/MessageReceived.jsx'
import MainPage from './components/MainPage.jsx'

const App = () => {
  const dispatch = useDispatch()
  const language = useSelector(selectCurrentLanguage)

  const domainPieces = process.env.REACT_APP_NODE_ENV !== "production" ? null : window.location.hostname.split('.')
  const domainEnd = process.env.REACT_APP_NODE_ENV !== "production" ? 'no' : domainPieces[domainPieces.length - 1]

  useEffect(() => {
  }, [language])

  const changeLanguage = (newLanguage) => {
    dispatch(setLanguage({ language: newLanguage }))
  }

  useEffect(() => {
    if (domainEnd === 'ee') changeLanguage(Language.Estonian)
    else if (domainEnd === 'no') changeLanguage(Language.Norwegian)
    else changeLanguage(Language.English)
  }, [domainEnd])

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<MainPage language={language} changeLanguage={changeLanguage} domainEnd={domainEnd} />} />
        <Route path='/message-received' element={<MessageReceived language={language} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
